import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";
import withSizes from "react-sizes";
import { Navbar, Button } from "react-bootstrap";

import { MOBILE_SIZE } from "../../constants";

const mapSizesToProps = ({ width }) => ({
  isMobile: width <= MOBILE_SIZE
});

const NavbarComponent = ({
  isAuth,
  username,
  // progetto,
  doLogout,
  history,
  location: { pathname },
  isMobile
}) => {
  return (
    <div style={{ paddingBottom: 30 }}>
      <Navbar bg="success" variant="dark">
        <Navbar.Brand style={{ fontWeight: 700 }} href="">
          Smart Aging
        </Navbar.Brand>
        {/* {isAuth && (
          <Navbar.Collapse className="justify-content-center">
            <Navbar.Brand>{progetto}</Navbar.Brand>
          </Navbar.Collapse>
        )} */}
        {isAuth && (
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text style={{ paddingRight: 15 }}>{username} </Navbar.Text>
            <Button variant="outline-light" onClick={doLogout}>
              Logout
            </Button>
          </Navbar.Collapse>
        )}
      </Navbar>
    </div>
  );
};

NavbarComponent.propTypes = {
  isAuth: PropTypes.bool,
  doLogout: PropTypes.func.isRequired
};

NavbarComponent.defaultProps = {
  isAuth: false
};

export default withRouter(withSizes(mapSizesToProps)(NavbarComponent));
