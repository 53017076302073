import React, { Component } from 'react';

import api from '../api';

const AuthContext = React.createContext();

const INITIAL_STATE = {email: '', active: false, name:'', lang:''};

class AuthProvider extends Component {
  state = INITIAL_STATE;

  login = async (username, password) => {
    const { data } = await api.authenticate(username, password);
    this.setState({ 
      email: "sgcreb@example.com",
      active: true, 
      name: "sgcreb",
      lang: "it",
    });
    api.setAuth(data.auth);
  }
  
  logout = () => {
    this.setState(INITIAL_STATE);
    api.removeAuth();
  }

  render() {
    return (
      <AuthContext.Provider
        value={{...this.state, login: this.login, logout: this.logout}}
      >
        {this.props.children}
      </AuthContext.Provider>
    )
  }
}
const AuthConsumer = AuthContext.Consumer
export { AuthProvider, AuthConsumer, AuthContext }