import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import ReactTable from "react-table";
import "react-table/react-table.css";
import api from "../../api";

const headerStyle = { fontWeight: "bold", textAlign: "left" };

const Home = ({ data, idpaziente }) => {
  const startGame = () => {
    //The ID of the patient and the authentication token will be passed to the game in the query string (/game?patient={id}&p={authToken})
    let token = api.getAuth();
    token = token.split(" ")[1];
    window.location.href = `/game?patient=${idpaziente}&p=${token}`;
  };

  const columns = [
    {
      Header: "Date",
      accessor: "resultDate",
    },
    {
      Header: "Points",
      accessor: "points",
    },
    // {
    //   Header: "Lives",
    //   accessor: "lives",
    // },
    // {
    //   Header: "Retries",
    //   accessor: "retries",
    // },
    // {
    //   Header: "Success",
    //   accessor: "success",
    // },
  ];

  return (
    <Container className="container-fluid">
      <Row style={{ paddingBottom: 10 }}>
        <Col>
          <h5>ID Paziente: {idpaziente}</h5>
        </Col>
        <Col md={"auto"}>
          <Button onClick={startGame} variant="success" size="sm">
            Gioca
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ReactTable
            columns={columns.map((col) => ({ ...col, headerStyle }))}
            data={data}
            sortable={false}
            showPagination={true}
            defaultPageSize={10}
            noDataText={"No records found."}
          ></ReactTable>
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
