import React, {useState, useEffect} from 'react';
import {withRouter} from 'react-router-dom'

import HomeComponent from './HomeComponent'

import api from '../../api'

const HomeContainer = ({match}) => {
    const idpaziente = match.params.idpaziente;
    const [isLoaded, setIsLoaded] = useState([])

    useEffect(() => api.getData(() => api.getActivities(idpaziente), setIsLoaded), [idpaziente])

    return (
        <HomeComponent data={isLoaded.data} idpaziente={idpaziente} />
    ) 
}

export default withRouter(HomeContainer)