import React, { useContext } from "react";
import { AuthContext } from "../../authentication";
import HeaderComponent from "./HeaderComponent";

const HeaderContainer = () => {
  const { active, logout } = useContext(AuthContext);
  const { username } = useContext(AuthContext);
  return (
    <HeaderComponent
      isAuth={active}
      username={username}
      // progetto={active ? progetti[0].projectName : ""}
      doLogout={logout}
    />
  );
};

HeaderContainer.propTypes = {};

HeaderContainer.defaultProps = {};

export default HeaderContainer;
