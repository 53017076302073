import axios from "axios";

const authApiConfig = {
  //   development: "http://localhost:8080/",
  development: "https://smartaging-auth.cbim.it/",
  production: "https://smartaging-auth.cbim.it/",
};

const gameApiConfig = {
  // development: "http://localhost:8080/",
  development: "https://sgs-rest.cbim.it/",
  production: "https://sgs-rest.cbim.it/",
};

const env = process.env.NODE_ENV || "development";
const authBaseURL = authApiConfig[env];
const authInstance = axios.create({ baseURL: authBaseURL });
const gameBaseURL = gameApiConfig[env];
const gameInstance = axios.create({ baseURL: gameBaseURL });

const authenticate = (email, password) => {
  const url = "api/v1/auth";
  return authInstance.post(url, null, { auth: { username: email, password } });
};

const getAuth = () => {
  return authInstance.defaults.headers.common["Authorization"];
};

const setAuth = (token) => {
  authInstance.defaults.headers.common["Authorization"] = "BEARER " + token;
  gameInstance.defaults.headers.common["Authorization"] = "BEARER " + token;
};

const removeAuth = () => {
  authInstance.defaults.headers.common["Authorization"] = undefined;
  delete authInstance.defaults.headers.common["Authorization"];
};

const getActivities = (id) => {
  // query al database where idpaziente = id
  const url = "api/v1/results/" + id;
  return gameInstance.get(url, null);
};

const getData = (api, setState) => {
  const load = async () => {
    try {
      const res = await api();
      setState(res);
    } catch (err) {
      console.error(err);
      setState(err);
    }
  };
  load();
};

export default {
  authenticate,
  getActivities,
  getData,
  getAuth,
  setAuth,
  removeAuth,
};
