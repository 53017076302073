import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { AuthContext } from "../authentication";
import { LOGIN } from "./routes";

const AuthRoute = ({ component: Component, claim, ...rest }) => {
  const { active } = useContext(AuthContext);

  // tutto ok
  if (active) {
    return <Route render={(props) => <Component {...props} />} {...rest} />;
  }

  // autenticazione da fare
  if (!active)
    return (
      <Route
        render={(props) => (
          <Redirect to={{ pathname: LOGIN, state: { from: props.location } }} />
        )}
        {...rest}
      />
    );

  // autenticato ma livello insufficiente
  // return (
  //   <Route
  //     render={props => active && claim.includes(role) ?
  //       <Component {...props} /> :
  //       <Redirect to={{pathname: UNAUTHORIZED}} />}
  //     {...rest}
  //   />
  // );
};

AuthRoute.propTypes = {};

AuthRoute.defaultProps = {};

export default AuthRoute;
